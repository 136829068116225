<script setup>
import { useFavoriteStore } from '~/stores/favorite.store';
import { useUserStore } from '~/stores/user.store';

const props = defineProps({
  listingId: {
    type: Number,
    required: true,
  },
  showBackground: {
    type: Boolean,
    default: false,
  },
  heartColor: {
    type: String,
    default: 'text-white',
  },
})
const favoriteStore = useFavoriteStore();
const userStore = useUserStore();
const isFavorite = ref(false);
const isOpen = ref(false);
const showAddNewCollectionInput = ref(false);
const isAuthorizedModalOpen = ref(false);
const favorites = ref([]);
const userFavoriteCollections = ref([]);
const collectionName = ref(undefined);
const fetchUserFavoriteListingSearch = async () => {
  favorites.value = await favoriteStore.fetchUserFavoriteListingSearch(props.listingId);

  if (favorites.value.data.length) {
    isFavorite.value = true;
  }
};
const addNewCollection = async () => {
  if (!collectionName.value) {
    return;
  }

  await favoriteStore.addUserFavoriteNewCollection(collectionName.value);
  collectionName.value = undefined;
  showAddNewCollectionInput.value = !showAddNewCollectionInput.value;
  openCollectionModal();
};
const removeListingFromFavorite = async () => {
  isFavorite.value = !isFavorite.value;

  await favoriteStore.removeUserFavoriteListing(props.listingId);
};
const openCollectionModal = async () => {
  if (userStore.user) {
    await favoriteStore.fetchUserFavoriteCollections();
    userFavoriteCollections.value = favoriteStore.userFavoriteCollections;
    isOpen.value = true;
  } else {
    isAuthorizedModalOpen.value = true;
  }
};
const selectCollection = async (collectionId) => {
  await favoriteStore.addUserFavoriteListing(collectionId, props.listingId);
  isFavorite.value = true;
  isOpen.value = false;
};
onMounted(() => {
  if (userStore.user) {
    fetchUserFavoriteListingSearch();
  }
})
</script>

<template>
  <div class="ms-auto rounded-full h-8 w-8 leading-8 text-center cursor-pointer"
  :class="{ 'bg-white/50': showBackground }"
    @click.prevent="isFavorite ? removeListingFromFavorite() : openCollectionModal()">
    <i class="fa-heart fa-lg"
      :class="{ [isFavorite ? 'fa-solid text-red-600' : `fa-regular ${heartColor}`]: true }"></i>
  </div>

  <w-modal v-model="isOpen" :transition="true" class="w-full" :ui="{ container: 'items-center' }">
    <div class="heart p-4">
      <h3 class="mb-3 text-2xl">{{ $t("client.add_to_favorite") }}</h3>
      <div>
        <div class="flex items-center gap-x-3 border-b pb-2 mb-2" v-for="collection in userFavoriteCollections">
          <input name="collection" type="radio" :checked="false"
            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
          <button @click="selectCollection(collection.id)" class="w-full text-left">
            {{ collection.name }}
          </button>
        </div>
        <div class="flex flex-col mt-3">
          <div @click="showAddNewCollectionInput = !showAddNewCollectionInput" class="cursor-pointer">
            <i class="fa-solid fa-plus me-2"></i>
            <span class="text-base text-indigo-900">{{ $t('client.or_add_new') }}</span>
          </div>
          <div class="flex items-center mt-2 gap-2" v-if="showAddNewCollectionInput">
            <input type="text" :placeholder="$t('client.new_collection')"
              class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
              v-model="collectionName" />
            <button type="button" class="bg-indigo-900 text-white p-2.5 rounded-lg" @click="addNewCollection">{{
              $t('client.add') }}</button>
          </div>
        </div>
      </div>
    </div>
  </w-modal>

  <w-modal v-model="isAuthorizedModalOpen" :transition="true" class="w-full">
    <div class="p-8 text-center flex flex-col gap-3">
      <h3 class="text-gray-900 text-2xl my-3">{{ $t("client.you_should_login_or_register_for_add_favorite") }}
      </h3>
    </div>
    <NuxtLink :to="localePath({ name: 'account.login' })"
      class="text-lg text-white bg-indigo-900 p-3 rounded-md text-center border-0 m-5 block hover:bg-indigo-800">
      {{ $t('client.login_register') }}
    </NuxtLink>
  </w-modal>
</template>


<style>
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

input[type="radio"]+button {
  position: relative;
  cursor: pointer;
  width: 100%;
  line-height: 20px;
}

input[type="radio"]+button::before {
  content: '';
  position: absolute;
  right: 0;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: inline-block;
}

input[type="radio"]:checked+button::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  position: absolute;
  right: 0;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  color: #312e81;
  font-size: 25px;
  line-height: 1;
}
</style>